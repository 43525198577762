// src/pages/Home.page.js
import React, { useContext, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useSwipeable } from 'react-swipeable';
import MyCalendar from '../components/Calendar';
import SocialFeed from '../components/SocialFeed';
import { UserContext } from '../contexts/user.context';
import Header from '../components/NavigationButtons';
import styled from 'styled-components';
import { Button } from '@mui/material';

export default function Home() {
  const { logOutUser } = useContext(UserContext);
  const { user, getName } = useContext(UserContext);
  const [index, setIndex] = useState(0);

  const ScrollableContainer = styled.div`
    height: 100vh;
    overflow-y: auto;
  `;

  const views = [
    <div key="1" style={{ width: '100%' }}>
      <div className="bg-calendar rounded-lg">
        <MyCalendar user={user} />
      </div>
    </div>,
    <div key="2" style={{ width: '100%' }}>
      <div className="bg-calendar">
          <SocialFeed />
      </div>
    </div>,
  ];

  const props = useSpring({
    transform: `translateX(-${index * 100}%)`,
    config: { tension: 300, friction: 30 },
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => setIndex((prevIndex) => Math.min(prevIndex + 1, views.length - 1)),
    onSwipedRight: () => setIndex((prevIndex) => Math.max(prevIndex - 1, 0)),
    swipeDuration: 300,
    preventDefaultTouchmoveEvent: true,
  });

  const logOut = async () => {
    try {
      const loggedOut = await logOutUser();
      if (loggedOut) {
        window.location.reload(true);
      }
    } catch (error) {
      alert(error);
    }
  };

  const goToNext = () => setIndex((prevIndex) => Math.min(prevIndex + 1, views.length - 1));
  const goToPrevious = () => setIndex((prevIndex) => Math.max(prevIndex - 1, 0));

  return (
    <div className='bg-calendar'>
      <Header goToNext={goToNext} goToPrevious={goToPrevious} name={getName()} />
      <div
        {...handlers}
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        <animated.div
          style={{
            display: 'flex',
            transform: props.transform,
            transition: 'transform 0.1s ease',
          }}
        >
          {views}
        </animated.div>
      </div>
      <div>
        <Button className="major-mono-display-regular" onClick={logOut}>
          Log Out
        </Button>
      </div>
    </div>
  );
}
