import { createContext, useState } from "react";
import { App, Credentials } from "realm-web";
import { APP_ID } from "../constants/constants";
// Creating a Realm App Instance
const app = new App(APP_ID);

// Creating a user context to manage and access all the user related functions
// across different components and pages.
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Insert a document
  async function insertData(log) {
    const mongodb = user.mongoClient("mongodb-atlas");
    const collection = mongodb.db("UserEntries").collection("Entries");

    try {
      // Convert the log object to an array of documents
      const entries = Object.keys(log).map(dateKey => {
        const entry = log[dateKey];

        // Return a document suitable for MongoDB
        return {
          _id: entry.id,
          day: entry.day,
          month: entry.month,
          year: entry.year,
          entryText: entry.text,
          image: entry.image,
          userId: user.id,  // Ensure you use the correct user ID
        };
      });

      if (entries.length > 0) {
        // Insert all entries at once into the database
        const result = await collection.insertMany(entries);
        console.log("Inserted documents:", result);
      } else {
        console.log("No valid entries to insert.");
      }
    } catch (err) {
      console.error("Failed to insert documents", err);
    }
  }

  async function deleteEntry(entryId) {
    try {
      const mongodb = user.mongoClient("mongodb-atlas");
      const collection = mongodb.db("UserEntries").collection("Entries");

      // Delete the document with the specified ID
      const result = await collection.deleteOne({ _id: entryId });

      if (result.deletedCount === 1) {
        console.log(`Successfully deleted entry with ID: ${entryId}`);
      } else {
        console.log(`No entry found with ID: ${entryId}`);
      }
    } catch (error) {
      console.error("Failed to delete entry", error);
    }
  }

  async function fetchUserEntriesAndSetLog(setLog) {
    try {
      const mongodb = user.mongoClient("mongodb-atlas");
      const collection = mongodb.db("UserEntries").collection("Entries");

      // Fetch documents from the collection
      const entries = await collection.find({ userId: user.id })// Ensure this is correct based on your setup


      // Process as array if entries is an array
      const updatedLog = entries.reduce((acc, entry) => {

        const date = new Date(entry.year, entry.month, entry.day);
        const dateKey = date.toDateString(); // Format date as `toDateString()`

        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push({
          id: entry._id, // Adjust based on how you handle IDs
          text: entry.entryText, // Mapping to match your format
          image: entry.image,
          day: entry.day,
          month: entry.month,
          year: entry.year,
          userId: entry.userId
        });
        return acc;
      }, {});

      setLog(updatedLog)
    } catch (error) {
      console.error("Failed to fetch user entries", error);
    }
  }

  async function fetchOtherUserEntries(otherUserId) {
    console.log("fetchinggggggg")
    try {
      const mongodb = user.mongoClient("mongodb-atlas");
      const collection = mongodb.db("UserEntries").collection("Entries");
  
      // Fetch all entries for the specified user
      const entriesCursor = collection.find({ userId: otherUserId });
      const entries = await entriesCursor; // Convert cursor to array
  
      // Create a map of entries grouped by date
      const updatedLog = entries.reduce((acc, entry) => {
        const date = new Date(entry.year, entry.month, entry.day);
        const dateKey = date.toDateString(); // Format date as `toDateString()`
  
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push({
          id: entry._id, // Ensure _id is correctly handled
          text: entry.entryText, // Ensure this matches your database schema
          image: entry.image,
          day: entry.day,
          month: entry.month,
          year: entry.year,
          userId: entry.userId
        });
        return acc;
      }, {});
  
      console.log("returningggggg");
      console.log(updatedLog);
  
      return updatedLog;
    } catch (error) {
      console.error("Failed to fetch user entries", error);
    }
  }
  



  async function getName() {
    try {
      // Make sure 'user' and 'user.id' are correctly defined
      if (!user || !user.id) {
        throw new Error("User or user ID is not defined");
      }
  
      const mongodb = user.mongoClient("mongodb-atlas");
      const collection = mongodb.db("UserEntries").collection("UserDetails");
  
      // Find documents matching the user ID
      const entries = await collection.find({ userId: user.id })// Convert cursor to array
  
      // Log the results for debugging
      console.log("User ID:", user.id);
      console.log("Entries:", entries);
  
      // Check if entries array is empty
      if (entries.length === 0) {
        return ""; // No entries found
      }
  
      // Assuming you want the first document's name
      return entries[0].firstName || ""; // Use the correct field name
    } catch (error) {
      console.error("Failed to fetch user name", error);
      return ""; // Return an empty string or handle the error appropriately
    }
  }
  

  // Function to log in user into our App Service app using their email & password
  const emailPasswordLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    const authenticatedUser = await app.logIn(credentials);
    setUser(authenticatedUser);
    return authenticatedUser;
  };

  // Function to sign up user into our App Service app using their email & password
  const emailPasswordSignup = async (email, password, name) => {
    try {
      await app.emailPasswordAuth.registerUser(email, password);
      // Since we are automatically confirming our users, we are going to log in
      // the user using the same credentials once the signup is complete.
      const entered = await emailPasswordLogin(email, password);
      insertName(name, entered)
      return entered
    } catch (error) {
      throw error;
    }
  };

  // Insert a document
  async function insertName(name, enteredUser) {
    const mongodb = enteredUser.mongoClient("mongodb-atlas");
    const collection = mongodb.db("UserEntries").collection("UserDetails");

    try {
      await collection.insertOne({
        _id: Date.now(),
        userId: enteredUser.id,
        firstName: name
      });
    }
    catch (err) {
      console.error("Failed to insert documents", err);
    }
  }

  // Function to fetch the user (if the user is already logged in) from local storage
  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();
      // Now, if we have a user, we are setting it to our user context
      // so that we can use it in our app across different components.
      setUser(app.currentUser);
      fetchUserEntriesAndSetLog();
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  }

  // Function to logout user from our App Services app
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      return true;
    } catch (error) {
      throw error
    }
  }

  async function fetchRandomEntries(setPosts) {
    try {
      console.log("trying");
  
      const mongodb = user.mongoClient("mongodb-atlas");
      const entriesCollection = mongodb.db("UserEntries").collection("Entries");
  
      // Step 1: Fetch 10 random IDs from the collection
      const randomEntries = await entriesCollection.aggregate([
        { $match: { userId: { $ne: user.id } } }, // Exclude entries from the current user
        { $sample: { size: 10 } }, // Get 10 random entries
        { $project: { _id: 1 } } // Only fetch the IDs
      ]); // Convert to array
  
      const entryIds = randomEntries.map(entry => entry._id);
  
      // Step 2: Fetch the full entries based on the random IDs
      const entriesWithDetails = await entriesCollection.aggregate([
        { $match: { _id: { $in: entryIds } } }, // Match the entries by IDs
        { 
          $lookup: {
            from: "UserDetails", // Join with UserDetails collection
            localField: "userId",
            foreignField: "userId",
            as: "userDetails"
          }
        },
        { $unwind: "$userDetails" }, // Unwind the userDetails array
        { 
          $project: {
            _id: 1,
            userId: "$userDetails.userId", // Include userId in the projection
            imageSrc: { $ifNull: ["$image", null] }, // Include entries even if 'image' is null
            caption: "$entryText",
            date: { $concat: [ { $toString: "$year" }, "-", { $toString: "$month" }, "-", { $toString: "$day" } ] },
            profileName: "$userDetails.firstName"
          }
        }
      ]); // Convert to array
  
      const posts = entriesWithDetails.map(entry => ({
        id: entry._id,
        userId: entry.userId, // Include userId in the posts
        profileName: entry.profileName || 'Unknown',
        imageSrc: entry.imageSrc || '', // Handle cases where imageSrc is null
        caption: entry.caption,
        date: new Date(entry.date).toDateString()
      }));
  
      setPosts(posts);
      console.log("Fetched random entries:", posts);
  
    } catch (error) {
      console.error("Failed to fetch random entries", error);
    }
  }

  async function fetchFollowedEntries(setPosts) {
    try {
      console.log("trying");
  
      const mongodb = user.mongoClient("mongodb-atlas");
      const entriesCollection = mongodb.db("UserEntries").collection("Entries");
      const followsCollection = mongodb.db("UserEntries").collection("Follows"); // Assume a collection named 'Follows'
  
      // Step 1: Get a list of users the current user follows
      const followedUsers = await followsCollection.find({ followerId: user.id });
      const followedUserIds = followedUsers.map(follow => follow.followeeId);
  
      if (followedUserIds.length === 0) {
        console.log("No followed users found.");
        setPosts([]);
        return;
      }
  
      // Step 2: Fetch 10 random entries from followed users
      const randomEntries = await entriesCollection.aggregate([
        { $match: { userId: { $in: followedUserIds } } }, // Include only entries from followed users
        { $sample: { size: 10 } }, // Get 10 random entries
        { $project: { _id: 1, userId: 1 } } // Fetch IDs and user IDs
      ]); // Convert to array
  
      const entryIds = randomEntries.map(entry => entry._id);
  
      // Step 3: Fetch the full entries based on the random IDs
      const entriesWithDetails = await entriesCollection.aggregate([
        { $match: { _id: { $in: entryIds } } }, // Match entries by IDs
        { 
          $lookup: {
            from: "UserDetails", // Join with UserDetails collection
            localField: "userId",
            foreignField: "userId",
            as: "userDetails"
          }
        },
        { $unwind: "$userDetails" }, // Unwind the userDetails array
        { 
          $project: {
            _id: 1,
            userId: 1,
            imageSrc: { $ifNull: ["$image", null] }, // Include entries even if 'image' is null
            caption: "$entryText",
            date: { $concat: [ { $toString: "$year" }, "-", { $toString: "$month" }, "-", { $toString: "$day" } ] },
            profileName: "$userDetails.firstName"
          }
        }
      ]); // Convert to array
  
      // Step 4: Format the entries
      const posts = entriesWithDetails.map(entry => ({
        id: entry._id,
        userId: entry.userId, // Include userId in the posts
        profileName: entry.profileName || 'Unknown',
        imageSrc: entry.imageSrc || '', // Handle cases where imageSrc is null
        caption: entry.caption,
        date: new Date(entry.date).toDateString()
      }));
  
      setPosts(posts);
      console.log("Fetched random entries:", posts);
  
    } catch (error) {
      console.error("Failed to fetch random entries", error);
    }
  }
  

  const followUser = async (followeeId) => {
    const mongodb = user.mongoClient("mongodb-atlas");
    const followsCollection = mongodb.db("UserEntries").collection("Follows");

    try {
      await followsCollection.insertOne({
        followerId: user.id,
        followeeId : followeeId,
      });
      console.log(`User ${user.id} followed user ${followeeId}`);
    } catch (error) {
      console.error("Failed to follow user", error);
    }
  };

  const unfollowUser = async (followeeId) => {
    const mongodb = user.mongoClient("mongodb-atlas");
    const followsCollection = mongodb.db("UserEntries").collection("Follows");
  
    try {
      const result = await followsCollection.deleteOne({
        followerId: user.id,
        followeeId: followeeId
      });
      console.log(`User ${user.id} unfollowed user ${followeeId}`);
      return result;
    } catch (error) {
      console.error("Failed to unfollow user", error);
      throw error;
    }
  };


  async function getFollowedUsers() {
    const mongodb = user.mongoClient("mongodb-atlas");
    const followsCollection = mongodb.db("UserEntries").collection("Follows");
  
    try {
      // Find all follow relationships where the given userId is the follower
      const follows = await followsCollection.find({ followerId: user.id });
      const followedUserIds = follows.map(follow => follow.followeeId);
      return followedUserIds;
    } catch (error) {
      console.error("Failed to get followed users", error);
      throw error;
    }
  };
  

  const getFollowers = async (userId) => {
    const mongodb = user.mongoClient("mongodb-atlas");
    const followsCollection = mongodb.collection('Follows');
  
    try {
      // Find all follow relationships where the given userId is the followee
      const follows = await followsCollection.find({ followeeId: userId });
      const followerIds = follows.map(follow => follow.followerId);
      return followerIds;
    } catch (error) {
      console.error("Failed to get followers", error);
      throw error;
    }
  };
  
  
  const isFollowing = async (userId) => {
    try {
        const followedUsers = await getFollowedUsers();
        return followedUsers.includes(userId);
    } catch (error) {
        console.error("Error checking if user is followed", error);
        return false; // or true, depending on what makes sense in your context
    }
};


  return <UserContext.Provider value={{ user, setUser, fetchUser, emailPasswordLogin, emailPasswordSignup, logOutUser, insertData, fetchUserEntriesAndSetLog, fetchOtherUserEntries, deleteEntry, fetchRandomEntries, getName, followUser, unfollowUser, getFollowedUsers, fetchFollowedEntries, isFollowing }}>
    {children}
  </UserContext.Provider>;
}