import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import Modal from 'react-modal';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { format } from 'date-fns';
import SimpleSocialMediaPost from './SimplePost';
import ViewJourney from '../components/ViewJourney';

Modal.setAppElement('#root');

const CalendarContainer = styled.div`
  width: 100vw; /* Make the width equal to the screen width */
  margin: 0 auto; /* Center horizontally if there's padding */
  padding: 20px;
  background: #DADDE5;
  border-radius: 8px;
  color: #fffffffff;
  font-size: 20px;

  .react-calendar {
    width: 100%; /* Make the calendar itself take up full width */
    height: 100%;
    border: none;
    background: #DADDE5;
  }

  .react-calendar__tile {
    background: #DADDE5;
    color: #fffffffff;
    &:hover {
      background: #61dafb;
      color: #ffffff;
    }
  }

  .react-calendar__tile--active {
    background: #61dafb !important;
    color: #282c34 !important;
  }

  .entry-exists {
    background-color: #4caf50; /* Green background */
    color: white; /* Text color */
  }
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw; /* Responsive width */
  max-height: 90vh; /* Responsive height */
  overflow: auto; /* Allow scrolling if content overflows */
`;


const UserCalendar = ({ fetchEntries, name }) => {
    const [date, setDate] = useState(new Date());
    const [isJourneyOpen, setIsJourneyOpen] = useState(false);
    const [log, setLog] = useState({});

    useEffect(() => {
        // Call fetchEntries and update state
        const fetchAndUpdateEntries = async () => {
            try {
                fetchEntries(setLog);
            } catch (error) {
                console.error("Failed to fetch entries", error);
            }
        };

        fetchAndUpdateEntries();
    }, [fetchEntries]); // Dependency array ensures this runs when fetchEntries changes

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const tileClassName = ({ date }) => {
        const dateString = date.toDateString();
        console.log(dateString);
        console.log(log);
        return log[dateString] ? 'entry-exists' : null;
    };

    return (
        <div>
            <CalendarContainer>
                <button
                    onClick={() => setIsJourneyOpen(true)}
                    className="amatic-sc-regular bg-banner  rounded-lg text-white py-2 px-4 hover:bg-green-600 transition duration-300"
                    style={{ width: '50vw', textAlign: 'center', fontSize: '20px' }}>
                    See {name}'s Journey
                </button>
                <Calendar
                    onChange={handleDateChange}
                    value={date}
                    tileClassName={tileClassName}
                />
                <div>
                    {log[date.toDateString()] && log[date.toDateString()].length > 0 ? (
                        <ul>
                            {log[date.toDateString()].map(entry => (
                                <li key={entry.id} className="mb-2 flex">
                                    <div className="flex justify-between items-center w-full">
                                        <SimpleSocialMediaPost
                                            caption={entry.text}
                                            imageSrc={entry.image}
                                            onDelete={null}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className='amatic-sc-regular'>No entries for this day.</p>
                    )}
                </div>
            </CalendarContainer>

            <Modal
                isOpen={isJourneyOpen}
                onRequestClose={() => setIsJourneyOpen(false)}
                contentLabel="View Journey"
                className="fixed inset-0 bg-white p-4 m-auto max-w-lg mx-auto rounded-lg shadow-lg"
                overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
            >
                <ModalContent>
                    <ViewJourney log={log} closeView={() => setIsJourneyOpen(false)} />
                </ModalContent>

            </Modal>
        </div>
    );
};

export default UserCalendar;
