import React from 'react';

const Recap = ({ log, selectedDate, closeRecap }) => {
  return (
    <div className="p-4">
      <h2 className="text-xl mb-4">{`Recap for ${formatDate(selectedDate)}`}</h2>
      {log[selectedDate.toDateString()] && log[selectedDate.toDateString()].length > 0 ? (
        <ul className="list-disc pl-5">
          {log[selectedDate.toDateString()].map(entry => (
            <li key={entry.id} className="mb-2 flex flex-col items-start">
              <div className="flex justify-between items-center w-full">
                <span>{entry.text}</span>
              </div>
              {entry.image && (
                <img
                  src={entry.image}
                  alt="Entry"
                  className="mt-2 w-32 h-32 object-cover rounded-lg"
                />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No entries for this date.</p>
      )}
      <button
        onClick={closeRecap}
        className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
      >
        Close
      </button>
    </div>
  );
};

// Helper function to format the date
const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
};

export default Recap;
