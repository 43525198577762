// src/components/Header.js
import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Banner from './Banner';

// Container for the entire header
const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column; /* Stack the banner and button row vertically */
  margin-bottom: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
  border-radius: 8px; /* Optional: add rounded corners to the shadow */
`;

// Banner section styling
const BannerSection = styled.div`
  background-color: #0A5787; /* Banner color */
  color: white;
  padding: 10px 20px; /* Add some padding */
`;

// Navigation button row styling
const NavButtonRow = styled.div`
  background-color: #085d91; /* Different shade of blue for the button row */
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  padding: 10px 0; /* Add some vertical padding */
`;

// Styled button for navigation with background colors
const NavButton = styled(Button)`
  background-color: ${(props) => props.bgColor || '#000'}; /* Default to black if no color is provided */
  color: white;
  margin: 0 10px; /* Space out the buttons */
  padding: 10px 20px; /* Add some padding inside the button */
  }
`;

function Header({ goToNext, goToPrevious, name }) {
    return (
        <HeaderContainer>
            <BannerSection>
                <Banner name={name} />
            </BannerSection>
            <NavButtonRow>
                <NavButton bgColor=" #0A5787" onClick={goToPrevious}>
                    Yours
                </NavButton>
                <NavButton bgColor=" #0A5787" onClick={goToNext}>
                    Others
                </NavButton>
            </NavButtonRow>
        </HeaderContainer>
    );
}

export default Header;
