import React, { useState, useRef, useEffect } from 'react';
import Calendar from 'react-calendar';
import Modal from 'react-modal';
import Recap from './Recap';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useContext } from 'react';
import { UserContext } from '../contexts/user.context';
import SimpleSocialMediaPost from './SimplePost';
import ViewJourney from '../components/ViewJourney';

Modal.setAppElement('#root');

const CalendarContainer = styled.div`
  width: 100vw; /* Make the width equal to the screen width */
  margin: 0 auto; /* Center horizontally if there's padding */
  padding: 20px;
  background: #DADDE5;
  border-radius: 8px;
  color: #fffffffff;
  font-size: 20px;

  .react-calendar {
    width: 100%; /* Make the calendar itself take up full width */
    height: 100%;
    border: none;
    background: #DADDE5;
  }

  .react-calendar__tile {
    background: #DADDE5;
    color: #fffffffff;
    &:hover {
      background: #61dafb;
      color: #ffffff;
    }
  }

  .react-calendar__tile--active {
    background: #61dafb !important;
    color: #282c34 !important;
  }

  .entry-exists {
    background-color: #4caf50; /* Green background */
    color: white; /* Text color */
  }
`;



const MyCalendar = ({ user }) => {
  const [date, setDate] = useState(new Date());
  const [newEntry, setNewEntry] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecapOpen, setIsRecapOpen] = useState(false);
  const { insertData, fetchUserEntriesAndSetLog, deleteEntry } = useContext(UserContext);
  const [log, setLog] = useState(() => {
    const storedLog = localStorage.getItem('calendarLog');
    return storedLog ? JSON.parse(storedLog) : {};
  });
  const [isJourneyOpen, setIsJourneyOpen] = useState(false);

  const fileInputRef = useRef(null); // Reference to the file input element

  const setSelectedDate = (date) => {
    if (log[date.toDateString()] == null) {
      setIsModalOpen(true)
    }
  }

  const handleAddEntry = async () => {
    if (newEntry.trim()) {
      const newLog = {
        id: Date.now(),
        text: newEntry,
        image: selectedImage,
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        userId: user.id,
      };

      setLog(prevLog => ({
        ...prevLog,
        [date.toDateString()]: [
          ...(prevLog[date.toDateString()] || []),
          newLog
        ]
      }));

      await insertData([newLog]);

      setNewEntry('');
      setSelectedImage(null);
      setIsModalOpen(false);
    }
  };

  const handleDeleteEntry = (entryId) => {
    setLog(prevLog => {
      const updatedEntries = prevLog[date.toDateString()].filter(entry => entry.id !== entryId);
      const newLog = { ...prevLog, [date.toDateString()]: updatedEntries };

      if (updatedEntries.length === 0) {
        delete newLog[date.toDateString()];
      }

      deleteEntry(entryId)
      return newLog;
    });
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setSelectedDate(newDate);
  };

  const tileClassName = ({ date }) => {
    const dateString = date.toDateString();
    return log[dateString] ? 'entry-exists' : null;;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Resize and compress
          if(img.width > 350 && img.height > 350) {
          canvas.width = img.width * 0.1;
          canvas.height = img.height * 0.1;
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Compress to 30% quality
          const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.3);
  
          // Store compressed image
          setSelectedImage(compressedDataUrl);
        };
  
        img.src = reader.result;
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  
  
  useEffect(() => {
    // Fetch data initially
    console.log("Setting up interval...");
    fetchUserEntriesAndSetLog(setLog);
  }, [fetchUserEntriesAndSetLog]);

  useEffect(() => {
    const storedLog = localStorage.getItem('calendarLog');

    if (storedLog) {
      console.log('Loaded log from localStorage:', JSON.parse(storedLog));
      setLog(JSON.parse(storedLog));
    } else {
      console.log('No log found in localStorage.');
    }
  }, []);

  useEffect(() => {
    console.log('Saving log to localStorage:', log);
    localStorage.setItem('calendarLog', JSON.stringify(log));
    setLog(log)
  }, [log]);

  const handleClickFileInput = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  return (
    <div>
      <div>
        <button
          onClick={() => setIsJourneyOpen(true)}
          className="amatic-sc-regular bg-banner text-white py-2 px-4 hover:bg-green-600 transition duration-300"
          style={{ width: '100vw', textAlign: 'center', fontSize: '20px' }}>
          See Your Journey
        </button>
      </div>
      <div className="flex flex-col bg-calendar p-10 items-center justify-center">
        <div className=" text-center">
          <CalendarContainer style={{ width: '100vw', textAlign: 'center', fontSize: '18px' }}>
            <h2>{format(date, 'MMMM do, yyyy')}</h2>
            <Calendar
              onChange={handleDateChange}
              value={date}
              tileClassName={tileClassName}
            />
          </CalendarContainer>
        </div>
        <div>
          {log[date.toDateString()] && log[date.toDateString()].length > 0 ? (
            <ul className="">
              {log[date.toDateString()].map(entry => (
                <li key={entry.id} >
                  <div className="flex justify-between items-center w-full">
                    <SimpleSocialMediaPost
                      caption={entry.text}
                      imageSrc={entry.image}
                      onDelete={() => handleDeleteEntry(entry.id)} />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className='amatic-sc-regular'>No entries for this day.</p>
          )}
        </div>

        {/* Modal for adding entries */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Add New Entry"
          className="fixed inset-0 bg-white p-4 m-auto max-w-lg mx-auto my-20 rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
        >
          <h2 className="amatic-sc-regular text-xl mb-4">Add New Entry</h2>
          <textarea
            value={newEntry}
            onChange={(e) => setNewEntry(e.target.value)}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            placeholder="Type your entry here..."
          />
          <button
            onClick={handleClickFileInput}
            className="bg-buttons text-white py-2 px-4 rounded-lg mt-4 hover:bg-blue-600 transition duration-300"
          >
            Add Image
          </button>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            ref={fileInputRef}
            className="hidden"
          />
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="mt-4 w-32 h-32 object-cover rounded-lg"
            />
          )}
          <div className="flex justify-center mt-4">
            <button
              onClick={handleAddEntry}
              className="bg-buttons text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Add Entry
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="ml-2 bg-buttons text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              Cancel
            </button>
          </div>
        </Modal>

        {/* Modal for viewing recap */}
        <Modal
          isOpen={isRecapOpen}
          onRequestClose={() => setIsRecapOpen(false)}
          contentLabel="Recap"
          className="fixed inset-0 bg-white p-4 m-auto max-w-lg mx-auto my-20 rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
        >
          <Recap log={log} selectedDate={date} closeRecap={() => setIsRecapOpen(false)} />
        </Modal>
        {/* Modal for viewing all entries */}
        <Modal
          isOpen={isJourneyOpen}
          onRequestClose={() => setIsJourneyOpen(false)}
          contentLabel="View Journey"
          className="fixed inset-0 bg-white p-4 m-auto max-w-lg mx-auto my-20 rounded-lg shadow-lg"
          overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
        >
          <ViewJourney log={log} closeView={() => setIsJourneyOpen(false)} />

        </Modal>
      </div>
    </div>
  );
};

export default MyCalendar;
