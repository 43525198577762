import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../contexts/user.context';
import SocialMediaPost from '../components/SocialMediaPost';

const SocialFeed = () => {
    const [posts, setPosts] = useState([]);
    const [activeTab, setActiveTab] = useState('random'); // Set "random" as the default active tab
    const { fetchFollowedEntries, fetchRandomEntries, getFollowedUsers } = useContext(UserContext);

    // Fetch the list of followed users
    const [followedUsers, setFollowedUsers] = useState([]);

    useEffect(() => {
        // Fetch followed users to manage follow status in posts
        const loadFollowedUsers = async () => {
            try {
                const users = await getFollowedUsers();
                setFollowedUsers(users);
            } catch (error) {
                console.error("Failed to fetch followed users", error);
            }
        };

        loadFollowedUsers();
    }, [getFollowedUsers]);

    // Fetch posts based on the active tab
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                if (activeTab === 'followed') {
                    await fetchFollowedEntries(setPosts);
                } else {
                    await fetchRandomEntries(setPosts);
                }
            } catch (error) {
                console.error("Failed to fetch posts", error);
            }
        };

        fetchPosts();
    }, [activeTab, fetchFollowedEntries, fetchRandomEntries]);

    // Function to refresh posts
    const refreshPosts = async () => {
        try {
            if (activeTab === 'followed') {
                await fetchFollowedEntries(setPosts);
            } else {
                await fetchRandomEntries(setPosts);
            }
        } catch (error) {
            console.error("Failed to refresh posts", error);
        }
    };

    // Sort posts by id in descending order
    const sortedPosts = [...posts].sort((a, b) => b.id - a.id);

    return (
        <div>
            <div className="flex justify-center">
                {/* Tab Navigation */}
                <button
                    onClick={() => setActiveTab('random')}
                    style={{ width: '50vw', textAlign: 'center', fontSize: '20px' }}
                    className={`px-4 ${activeTab === 'random' ? 'bg-banner text-white' : 'bg-gray-300 text-black'} transition duration-300 amatic-sc-regular text-white py-2 px-4 transition duration-300`}
                >
                    New Journeys
                </button>
                <button
                    onClick={() => setActiveTab('followed')}
                    style={{ width: '50vw', textAlign: 'center', fontSize: '20px' }}
                    className={`px-4 ${activeTab === 'followed' ? 'bg-banner text-white' : 'bg-gray-300 text-black'} transition duration-300 amatic-sc-regular text-white py-2 px-4 transition duration-300`}
                >
                    Followed
                </button>
            </div>
            <div className="flex flex-col items-center justify-center" style={{ width: '100%', marginTop: '20px' }}>
                {sortedPosts.length === 0 ? (
                    <div className="text-center text-gray-600">
                        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>No posts available</p>
                        <p>It looks like you don't have any posts to display right now.</p>
                        <p>Follow more people to see their posts here!</p>
                    </div>
                ) : (
                    sortedPosts.map(post => (
                        <SocialMediaPost
                            key={post.id}
                            profileName={post.profileName}
                            imageSrc={post.imageSrc}
                            caption={post.caption}
                            date={post.date}
                            userId={post.userId}
                            isFollowing={followedUsers.includes(post.userId)} // Pass follow status to the post
                            refreshPosts={refreshPosts} // Pass refresh function to the post
                            className="mb-4 w-full max-w-md" // Ensure posts have a consistent width
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default SocialFeed;
