import React from 'react';

const ViewJourney = ({ log, closeView }) => {
  return (
    <div className="p-4">
      <h2 className="text-xl mb-4">All Entries</h2>
      {Object.keys(log).length === 0 ? (
        <p>No entries found.</p>
      ) : (
        <div>
          {Object.keys(log).map(date => (
            <div key={date} className="mb-4">
              <h3 className="text-lg font-semibold mb-2">{date}</h3>
              <ul className="list-disc pl-5">
                {log[date].map(entry => (
                  <li key={entry.id} className="mb-2 flex flex-col items-start">
                    <div className="flex justify-between items-center w-full">
                      <span>{entry.text}</span>
                    </div>
                    {entry.image && (
                      <img
                        src={entry.image}
                        alt="Entry"
                        className="mt-2 w-32 h-32 object-cover rounded-lg"
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      <button
        onClick={closeView}
        className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
      >
        Close
      </button>
    </div>
  );
};

export default ViewJourney;
