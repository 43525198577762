import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { UserContext } from '../contexts/user.context';
import UserCalendar from './UserCalendar';
import Modal from 'react-modal';

Modal.setAppElement('#root');

// Container for the entire post
const PostContainer = styled.div`
  position: relative;  
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px;
  width: 90vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

// Container for profile name
const ProfileContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  position: relative;
  cursor: pointer; /* Make it look clickable */
`;

// Profile name styling
const ProfileName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

// Follow/Unfollow button styling
const FollowButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #0056b3;
`;

// Post image styling
const PostImage = styled.img`
  width: 100%;          
  height: auto;         
  max-height: 300px;    
  object-fit: contain;  
  margin: 0 auto;       
`;

// Container for post content and like button
const PostContent = styled.div`
  padding: 16px;
  flex: 1;
`;

// Caption styling
const PostCaption = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

// Date styling
const PostDate = styled.span`
  display: block;
  font-size: 14px;
  color: #888;
  margin-top: 8px;
`;

// Like button styling
const LikeButton = styled.button`
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #0056b3;
`;

const SocialMediaPost = ({ profileName, imageSrc, caption, date, userId, refreshPosts }) => {
  const [followed, setFollowed] = useState(false);
  const { followUser, unfollowUser, getFollowedUsers, fetchOtherUserEntries } = useContext(UserContext);
  const [isViewing, setIsViewing] = useState(false);

  useEffect(() => {
    const checkIfFollowing = async () => {
      try {
        const followedUsers = await getFollowedUsers();
        setFollowed(followedUsers.includes(userId));
      } catch (error) {
        console.error("Error fetching followed users", error);
      }
    };

    checkIfFollowing();
  }, [userId, getFollowedUsers, refreshPosts]);

  const handleFollowClick = async (event) => {
    event.stopPropagation(); // Prevent triggering the modal
    try {
      if (followed) {
        await unfollowUser(userId);
      } else {
        await followUser(userId);
      }
      setFollowed(!followed);
      refreshPosts();
    } catch (error) {
      console.error("Error following/unfollowing user", error);
    }
  };

  const openJourney = () => {
    setIsViewing(true);
  };

  const closeJourney = () => {
    setIsViewing(false);
  };

  async function handleFetchEntries(setLog) {
    try {
      const entriesLog = await fetchOtherUserEntries(userId);
      setLog(entriesLog);
      console.log(entriesLog);
    } catch (error) {
      console.error("Error fetching entries", error);
    }
  }

  return (
    <PostContainer>
      <ProfileContainer onClick={openJourney}>
        <ProfileName>{profileName}</ProfileName>
        <FollowButton onClick={handleFollowClick}>
          {followed ? 'Unfollow' : 'Follow'}
        </FollowButton>
      </ProfileContainer>
      {imageSrc && <PostImage src={imageSrc} alt="Post image" />}
      <PostContent>
        <PostCaption>{caption}</PostCaption>
        <PostDate>{date}</PostDate>
      </PostContent>
      <LikeButton>Celebrate</LikeButton>

      {/* Modal for viewing journey */}
      <Modal
        isOpen={isViewing}
        onRequestClose={closeJourney}
        contentLabel="View Journey"
        className="fixed inset-0 bg-calendar"
      >
        <UserCalendar name={profileName} fetchEntries={handleFetchEntries} />
        <button
          onClick={closeJourney}
          className="absolute top-4 right-4 bg-red-500 text-white rounded-full p-2"
        >
          Close
        </button>
      </Modal>
    </PostContainer>
  );
};

export default SocialMediaPost;
