import React from 'react';
import styled from 'styled-components';

// Container for the post
const PostContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 16px;
  width: 90vw; /* Adjust width as needed */
  overflow: hidden;
`;


// Post image styling
const PostImage = styled.img`
  width: 100%;          /* Make the image take up the full width of the container */
  height: auto;         /* Maintain the aspect ratio while adjusting the height */
  max-height: 300px;    /* Optionally, limit the maximum height to prevent it from getting too large */
  object-fit: contain;  /* Scale the image to fit inside the container without cropping */
  margin: 0 auto;       /* Center the image horizontally */
`;

// Container for post content
const PostContent = styled.div`
  padding: 16px;
`;

// Caption styling
const PostCaption = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

// Container for the delete button
const DeleteButton = styled.button`
  display: block;
  margin: 16px auto;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #e57373;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f44336;
  }
`;

const SimpleSocialMediaPost = ({ imageSrc, caption, onDelete }) => {
 
  const showImage = imageSrc != null
  const showDelete = onDelete != null
  return (
    <PostContainer>
      {showImage && <PostImage src={imageSrc} alt="Post image" />}
      <PostContent>
        <PostCaption>{caption}</PostCaption>
      </PostContent>
      {showDelete && <DeleteButton onClick={onDelete}>Delete</DeleteButton>}
    </PostContainer>
  );
};

export default SimpleSocialMediaPost;
