// src/components/Banner.js
import React, { useState, useEffect, useContext } from 'react';
import '../App.css';
import { UserContext } from '../contexts/user.context';
import InstallButton from '../components/InstallButton';
const Banner = () => {
  const [userName, setUserName] = useState('');
  const { getName } = useContext(UserContext);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const name = await getName(); // Fetch the user’s name
        setUserName(name); // Set the user’s name in state
      } catch (error) {
        console.error("Failed to fetch user name", error);
      }
    };

    fetchUserName();
  }, [getName]);

  return (
    <div className="w-full bg-banner text-white text-center mb-1 ">
      <h1 className="major-mono-display-regular text-3xl font-bold">Journey</h1>
      <p className="major-mono-display-regular text-lg mt-1">{userName}, this is yours.</p>
      <InstallButton/>
    </div>
  );
};

export default Banner;
